/* this is the ES6 version of the code
 * 
 * It uses ES6 Class, alongside arrow functions for speed of development
 * Use Babel with a fetch() polyfill (fetch.umd.js) to transpile down for older browsers
 * 
 * 
 */

class Testimonials {

    constructor() {

        this.button = document.getElementById('add-review');

        if (!this.button) {
            return;
        }

        this.ajaxUrl = '/testimonials/form';
        this.setTrigger();

    }

    setTrigger() {

        this.button.addEventListener('click', (e) => {
     
            let suffix = new Date().getTime();
            let newPostUrl = new URL(`${this.ajaxUrl}/fetch?${suffix}`, document.location.origin);
                      
            this.getForm(newPostUrl);
        });
    }

    getForm(newPostUrl) {
 
        fetch(newPostUrl).then(response => {
            return response.text();
        })
                .then((r) => {
                    var f = document.createDocumentFragment();
                    var d = document.createElement('div');
                    d.classList.add('overlay');
                    d.insertAdjacentHTML('afterbegin', r);
                    f.appendChild(d);
                    document.body.appendChild(f);
                    this.setDynamicTrigger();
                });
    }
    /* set the form submit button dynamically */
    setDynamicTrigger() {

        let _form = document.querySelector('form.review-form');
        if (!_form) {
            return;
        }
        let _btn = _form.querySelector('button');
        _btn.addEventListener('click', (b) => {
            if (_form.checkValidity()) {
                b.preventDefault();
                b.target.classList.add('active');
                this.postForm();
            }
        });

        let _close = _form.querySelector('.close');
        _close.addEventListener('click', (e) => {
            document.querySelector('.overlay').parentNode.removeChild(document.querySelector('.overlay'));

        });

    }

    postForm() {
        let fForm = document.querySelector('.review-form');
        let postUrl = `${this.ajaxUrl}?ajax=1`;
        let data = new FormData(fForm);
        fetch(postUrl,{method:'post',body:data}).then(response=> {
            return response.text();
        }).then(r=>{
            fForm.querySelector('fieldset').parentNode.removeChild( fForm.querySelector('fieldset'));
            fForm.insertAdjacentHTML('beforeend',r);
        });


    }

    clearForm() {}

}

window.addEventListener('DOMContentLoaded', () => {
    new Testimonials();
});


